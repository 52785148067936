<template>
 <div id="app">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-11">
                                <h5 class="card-title">DASHBOARD STAGING KREDIT 3 KEKUATAN</h5>
                            </div>
                            <div class="col-md-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <button class="btn btn-info btn-sm btn-padding" @click="toHome"><i class="fa fa-money"></i></button>        
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-warning btn-sm btn-padding" @click="toChangePassword"><i class="fa fa-key"></i></button>        
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-danger btn-sm btn-padding" @click="toSignOut"><i class="fa fa-power-off"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                             <!-- begin filter -->
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-inline">
                                            <label class="text-filter">Kode Area/Cabang</label>
                                            <Select2 class="col-sm-8 mr-2" v-model="list.area" :options="areaopt" :settings="{width:'100%', allowClear: true}" @change="loadPostsData" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-inline">
                                            <label class="text-filter">Kekuatan</label>
                                            <Select2 class="col-sm-5" v-model="list.strength" :options="strengthopt" :settings="{width:'100%', allowClear: true}" @change="strengthUpdate" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-inline">
                                    <label class="text-filter">Group Nasabah/Ekosistem/Kementerian</label>
                                    <Select2 class="col-sm-6" v-model="list.customer_group" :options="customergroupopt" :settings="{width:'100%', allowClear: true}" @change="loadPostsData" />
                                </div>
                            </div>
                            <!-- end filter -->
                            <div class="col-md-12 mt-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="col-md-12">
                                            <div v-for="data in dataStaging" :key="data.titleStaging" v-bind:class="data.classCard">
                                                <div class="card-header">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h5 class="card-title mb-0">{{data.titleStaging}}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-10">
                                                            <div class="row">
                                                                <div v-for="item in data.detail" :key="item.title" v-bind:class="item.classRow" v-bind:style="'background: url('+item.bgImage+'); background-repeat:no-repeat; background-size: 100% 100%;'">
                                                                    <div class="card card-nobg">
                                                                        <div v-bind:class="item.classText">{{item.title}}</div>
                                                                        <div class="card-body small-padding">
                                                                            <div class="row ">
                                                                                <div v-bind:class="item.classTextNote">
                                                                                    {{item.countDebitur}} Debitur
                                                                                </div>
                                                                                <div v-bind:class="item.classTextNote">
                                                                                    <span class="pull-left">Limit :</span>
                                                                                    Rp {{item.limitMoney}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <div class="col-md-12" v-for="summ in data.summary" :key="summ.title" >
                                                                    <div class="card">
                                                                        <div v-bind:class="summ.classCardHeader">
                                                                            {{summ.title}}
                                                                        </div>
                                                                        <div class="card-body padding-summary">
                                                                            <div class="col-md-12">
                                                                                <div class="row">
                                                                                    <div class="col-md-12 text-summary text-right font-weight-bold">
                                                                                        {{summ.countDebitur}} Debitur
                                                                                    </div>
                                                                                    <div class="col-md-12 text-summary text-right font-weight-bold"><span class="pull-left">Limit :</span> Rp {{summ.limitMoney}}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
 </div>
</template>

<script>
    import axios from 'axios';
    import Select2 from 'v-select2-component';
    import bgOne from '@/assets/images/one.png';
    import bgTwo from '@/assets/images/two.png';
    import bgThree from '@/assets/images/three.png';
    import bgFour from '@/assets/images/four.png';
    import bgFive from '@/assets/images/five.png';
    import bgSix from '@/assets/images/six.png';
    import bgSeven from '@/assets/images/seven.png';

    export default {
        name : 'credit',
        components : {Select2},
        created() {
            //MAKA AKAN MENJALANKAN FUNGSI BERIKUT
            this.loadPostsData();
            this.areaList();
            this.customerGroupList();
            this.strengthList();
        },
        data() {
            return {
                areaopt : [{ text: '---', id: '' }],
                customergroupopt : [{ text: '---', id: '' }],
                strengthopt : [{ text: '---', id: '' }],
                search: '',
                list : {
                    area:'',
                    strength : '',
                    customer_group : ''
                },
                dataStaging : [
                    {
                        titleStaging: 'Staging SME',
                        classCard : "card",
                        detail : [
                            {
                                title: '01. Targeted Customer',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgOne,
                                countDebitur : 0,
                                limitMoney : "0 Miliar",
                            },
                            {
                                title: '02. Collecting Data',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgTwo,
                                countDebitur : 0,
                                limitMoney : "0 Miliar",
                            },
                            {
                                title: '03. NAK',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgThree,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: '04. RAK',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgFour,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'SPPK',
                                classRow : 'col-md-3 ml-auto mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgFive,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'PK',
                                classRow : 'col-md-3 mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgSix,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Efektif Booking',
                                classRow : 'col-md-3 mr-auto  mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgSeven,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            }
                        ],
                        summary : [
                            {
                                title : 'Total Pipeline',
                                classCardHeader : 'card-header bg-light font-weight-bold',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title : 'Cancel/Reject',
                                classCardHeader : 'card-header bg-secondary font-weight-bold text-white',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            }
                        ]
                    },
                    {
                        titleStaging: 'Staging KUM/KUR',
                        classCard : "card mt-5",
                        detail : [
                            {
                                title: 'Targeted Customer',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgOne,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Collecting Data',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgTwo,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Scoring (By LOS)',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgThree,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Approval',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgFour,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'PK',
                                classRow : 'col-md-3 ml-auto mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgFive,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Efektif Booking',
                                classRow : 'col-md-3 mr-auto mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgSix,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                        ],
                        summary : [
                            {
                                title : 'Total Pipeline',
                                classCardHeader : 'card-header bg-light font-weight-bold',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title : 'Cancel/Reject',
                                classCardHeader : 'card-header bg-secondary font-weight-bold text-white',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            }
                        ]
                    },
                    {
                        titleStaging: 'Staging KSM',
                        classCard : "card mt-5",
                        detail : [
                            {
                                title: 'Targeted Customer',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgOne,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Collecting Data',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgTwo,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Scoring (By LOS)',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgThree,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Approval (By LOS)',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgFour,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Efektif Booking',
                                classRow : 'col-md-3 ml-auto mr-auto mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgFive,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                        ],
                        summary : [
                            {
                                title : 'Total Pipeline',
                                classCardHeader : 'card-header bg-light font-weight-bold',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title : 'Cancel/Reject',
                                classCardHeader : 'card-header bg-secondary font-weight-bold text-white',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            }
                        ]
                    },
                    {
                        titleStaging: 'Staging KPR',
                        classCard : "card mt-5",
                        detail : [
                            {
                                title: 'Pipeline',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgOne,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'On Hand & DTBO',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgTwo,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Application In Process',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgThree,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'SPPK',
                                classRow : 'col-md-3',
                                classText: 'card-header card-nobg text-card-header small-padding',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold',
                                bgImage : bgFour,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                             {
                                title: 'Akad/PK',
                                classRow : 'col-md-3 ml-auto mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgFive,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Efektif Booking',
                                classRow : 'col-md-3 mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgSix,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title: 'Efektif Booking',
                                classRow : 'col-md-3 mr-auto  mt-5',
                                classText: 'card-header card-nobg text-card-header small-padding text-black-50',
                                classTextNote : 'col-md-12 text-card-note text-right font-weight-bold text-black-50',
                                bgImage : bgSeven,
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            }
                        ],
                        summary : [
                            {
                                title : 'Total Pipeline',
                                classCardHeader : 'card-header bg-light font-weight-bold',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            },
                            {
                                title : 'Cancel/Reject',
                                classCardHeader : 'card-header bg-secondary font-weight-bold text-white',
                                countDebitur : 0,
                                limitMoney : "11,5 Miliar",
                            }
                        ]
                    }
                ]
            }
        },
        methods: {
            loadPostsData() {
                this.getStagingData();
            },
            toHome() {
                this.$router.push({path : '/' });
            },
            areaList() {
                let vm = this;
                axios.post(this.$root.baseUrlApi+'functions/area.php',{},
                {
                    headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
                }).then(function (response) {
                    vm.areaopt = response.data;
                    
                }).catch(function (error) {
                    console.log(error);
                    vm.areaopt = [];
                    if (!error.response) {
                        alert("Please check your connection and try again");
                    } else {
                        if (error.response.data.message) {
                            alert(error.response.data.message);
                        } else {
                            alert("error occured in server");
                        }
                    }

                    // setTimeout(() => {
                    //     this.reqList();
                    // }, 6000);
                });
        
            },
            customerGroupList() {
                let vm = this;
                this.filter = {strength:vm.list.strength};
                axios.post(this.$root.baseUrlApi+'functions/customer_group.php',this.filter,
                {
                    headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
                }).then(function (response) {
                    vm.customergroupopt = response.data;
                    console.log(response);
                }).catch(function (error) {
                    console.log(error);
                    vm.customergroupopt = [];
                    if (!error.response) {
                        alert("Please check your connection and try again");
                    } else {
                        if (error.response.data.message) {
                            alert(error.response.data.message);
                        } else {
                            alert("error occured in server");
                        }
                    }
                });
            },
            strengthList() {
                let vm = this;
                axios.post(this.$root.baseUrlApi+'functions/strength.php',{},
                {
                    headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
                }).then(function (response) {
                    vm.strengthopt = response.data;
                    console.log(response);
                }).catch(function (error) {
                    console.log(error);
                    vm.strengthopt = [{ text: '---', id: '' }];
                    if (!error.response) {
                        alert("Please check your connection and try again");
                    } else {
                        if (error.response.data.message) {
                            alert(error.response.data.message);
                        } else {
                            alert("error occured in server");
                        }
                    }

                    // setTimeout(() => {
                    //     this.reqList();
                    // }, 6000);
                });
            },
            getStagingData() {
                let vm = this;
                axios.post(this.$root.baseUrlApi+'functions/staging/credit.php',this.list,
                {
                    headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
                }).then(function (response) {
                    for (var obj in vm.dataStaging) {
                        for (var ix in vm.dataStaging[obj].detail) {
                            vm.dataStaging[obj].detail[ix].title = response.data[obj].detail[ix].title;
                            vm.dataStaging[obj].detail[ix].countDebitur = response.data[obj].detail[ix].debitur;
                            vm.dataStaging[obj].detail[ix].limitMoney = response.data[obj].detail[ix].limit + ' Miliar';
                        }

                        for (var idx in vm.dataStaging[obj].summary) {
                            vm.dataStaging[obj].summary[idx].countDebitur = response.data[obj].summary[idx].debitur;
                            vm.dataStaging[obj].summary[idx].limitMoney = response.data[obj].summary[idx].limit_sep + ' Miliar';
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                    vm.areaopt = [];
                    if (!error.response) {
                        alert("Please check your connection and try again");
                    } else {
                        if (error.response.data.message) {
                            alert(error.response.data.message);
                        } else {
                            alert("error occured in server");
                        }
                    }

                    // setTimeout(() => {
                    //     this.reqList();
                    // }, 6000);
                });
        
            },
            toChangePassword(e) {
                e.preventDefault();
                this.$router.push({path : '/changepassword' });
            },
            strengthUpdate() {
                // alert(this.list.strength);
                // this.list.strength = e.target.value;
                this.list.customer_group = '';
                this.customerGroupList();
                this.loadPostsData();
            },
            toSignOut(e) {
                e.preventDefault();
                localStorage.removeItem('accessTokenDashboard');
                localStorage.removeItem('authenticatedDashboard');
                this.$parent.$data.authenticated = false;
                this.$parent.$data.accessToken = '';
                this.$parent.$data.list = {};
                this.$data.areaopt = [{ text: '---', id: '' }];
                this.$data.customergroupopt = [{ text: '---', id: '' }];
                this.$forceUpdate();
            }
        }
    }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px !important;
    }
}

@media (min-width: 1280px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1280px !important;
    }
}

@media (min-width: 1365px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1366px !important;
    }
}

.container {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important; 
    margin-right: auto;
    margin-left: auto;
}
</style>
